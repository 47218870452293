import * as React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components"

const NotFoundPage: React.FC = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Not Found | Papelzinho ™</title>
        </Helmet>

        <section className="px-5 py-5 text-center">
          <h3>404 - Not found</h3>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage
